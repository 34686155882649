export const debounce = (function () {
    let timer = 0
    let lastTimer = 0
    return function (fn, delay = 500, immediate = true) {
        if (timer) {
            clearTimeout(timer)
        }
        if (immediate) {
            // 立即执行
            let callNow = !timer
            timer = setTimeout(() => {
                if (lastTimer !== timer) {
                    timer = 0
                    lastTimer = 0
                    fn()
                }
            }, delay)
            if (callNow) {
                lastTimer = timer
                fn()
            }
        } else {
            // 非立即执行
            timer = setTimeout(() => {
                fn()
                timer = 0
            }, delay)
        }
    }
})();