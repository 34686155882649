<template>
  <div  style="margin-top: 46px">
    <van-tabs v-model="active" animated>
      <van-tab title="发送短信">
        <div class="orderForm">
          <van-cell-group>
            <van-field
                v-model="form.msisdn"
                type="digit" label="msisdn"
                :rules="[{ required: true, message: '请填写msisdn' }]"
            />
            <van-field
                v-model="form.content"
                rows="2"
                autosize
                label="短信内容"
                type="textarea"
                maxlength="140"
                placeholder="请输入短信内容"
                show-word-limit
                :rules="[{ required: true, message: '请输入短信内容' }]"
            />
          </van-cell-group>
          <van-button type="primary" block @click="sendSms">发送短信</van-button>
        </div>
      </van-tab>
      <van-tab title="收件箱">
        <div style="display: flex; align-items: center">
          <van-search
              style="flex: 3"
              @input="(value) => onMsisdnChange(value, 'inBox', 2)"
              v-model="inBox.search.msisdn"
              placeholder="请输入搜索msisdn"
              maxlength="40"
          />
          <van-field
              style="flex: 2;"
              readonly
              clickable
              label="状态："
              label-width="auto"
              :value="inBox.statusText"
              placeholder="选择状态"
              @click="inBox.showPicker = true"
          />
        </div>
        <div
            v-for="msg in inBox.list" :key="msg.id"
            style="display: flex; align-items: center; margin: 5px 12px; height: 50px; border-bottom: #22222220 1px solid"
            @click="onMessageClick(msg)"
        >
          <div
              style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              background:#aaaaaa;
              margin-right: 5px"
          >
            <van-icon name="comment-o" size="38px" color="#ffffff" style="margin: 0 5px"/>
          </div>
          <div style="font-size: 14px; text-align: left; width: calc(100% - 58px)">
            <div style="color: #bd2728">{{ msg.msisdn }}</div>
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ msg.message }}</div>
            <div>
              <span>{{ msg.createTime }} </span>
              <van-tag style="float: right" :type="smsStatus.find((i) => i.value === msg.status).type">{{smsStatus.find((i) => i.value === msg.status).label}}</van-tag>
            </div>
          </div>
        </div>
        <van-pagination
            @change="(page) => {this.onPageChange(page, 'inBox')}"
            v-model="inBox.pageInfo.page"
            :page-count="inBox.pageInfo.count"
            :show-page-size="5"/>
      </van-tab>
      <van-tab title="发件箱">
        <div style="display: flex; align-items: center">
          <van-search
              style="flex: 3"
              @input="(value) => onMsisdnChange(value, 'sentSms', 1)"
              v-model="sentSms.search.msisdn"
              placeholder="请输入搜索msisdn"
              maxlength="40"
          />
          <van-field
              style="flex: 2;"
              readonly
              clickable
              label="状态："
              label-width="auto"
              :value="sentSms.statusText"
              placeholder="选择状态"
              @click="sentSms.showPicker = true"
          />
        </div>
        <div
            v-for="msg in sentSms.list" :key="msg.id"
            style="display: flex; align-items: center; margin: 5px 12px; height: 50px; border-bottom: #22222220 1px solid"
            @click="onMessageClick(msg)"
        >
          <div
              style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              background:#aaaaaa;
              margin-right: 5px"
          >
            <van-icon name="comment-o" size="38px" color="#ffffff" style="margin: 0 5px"/>
          </div>
          <div style="font-size: 14px; text-align: left; width: calc(100% - 58px)">
            <div style="color: #bd2728">{{ msg.msisdn }}</div>
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ msg.message }}</div>
            <div>
              <span>{{ msg.createTime }}</span>
              <van-tag style="float: right" :type="smsStatus.find((i) => i.value === msg.status).type">{{smsStatus.find((i) => i.value === msg.status).label}}</van-tag>
            </div>
          </div>
        </div>
        <van-pagination
            @change="(page) => {this.onPageChange(page, 'sentSms')}"
            v-model="sentSms.pageInfo.page"
            :page-count="sentSms.pageInfo.count"
            :show-page-size="5"
        />
      </van-tab>
    </van-tabs>
    <van-popup v-model="inBox.showPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="smsStatus"
          value-key="label"
          @cancel="inBox.showPicker = false"
          @confirm="(value) => onConfirm(value,'inBox')"
      />
    </van-popup>
    <van-popup v-model="sentSms.showPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="smsStatus"
          value-key="label"
          @cancel="sentSms.showPicker = false"
          @confirm="(value) => onConfirm(value,'sentSms')"
      />
    </van-popup>
  </div>
</template>
<script>
import {Toast} from "vant";
import {mapState} from "vuex";
import {debounce} from "@/utils/debounce";

export default {
  name: 'smsCharge',
  data() {
    return {
      flag: true,
      arrs: {},
      form: {
        msisdn: '',
        content: ''
      },
      pack: [],
      cardStatus: {
        type: 'success',
        label: '正常'
      },
      current: 0,
      chargeOrderId: '',
      timer: Object,
      active: 0,
      currentPage: 1,
      sentSms: {
        pageInfo: {
          page: 1,
          pageSize: 10,
          count: 1,
        },
        list: [],
        search: {
          msisdn: "",
          status: "",
          dir: 1,
        },
        showPicker: false,
        statusText: "",
      },
      inBox: {
        pageInfo: {
          page: 1,
          pageSize: 10,
          count: 1,
        },
        list: [],
        search: {
          msisdn: "",
          status: "",
          dir: 2,
        },
        showPicker: false,
        statusText: "",
      },
    }
  },
  computed: {
    ...mapState({
      smsStatus: state => state.dictionaries.sendStatus,
    }),
  },
  methods: {
    sendSms() {
      this.$api.smsSendSave({
        content: this.form.content,
        msisdns: [this.form.msisdn],
        templateId: 0,
        sendCount: 1
      }).then(res => {
        if (res.data.code == 0) {
          Toast.success('提交成功');
          this.form = {
            msisdn: '',
            content: ''
          }
        }
      })
    },
    fetchData(params, objName){
      this.$api.getSmsList(params).then(res => {
        if(res.data.code === 0){
          const {hasNextPage, list} = res.data.data;
          this[objName].list = list;
          if(hasNextPage) this[objName].pageInfo.count = this[objName].pageInfo.page + 1;
        }
      });
    },
    onPageChange(page, objName) {
      this[objName].pageInfo.page = page;
      const params = this.getSearchParams(this[objName]);
      this.fetchData(params, objName);
    },
    onMessageClick({msisdn,message}){
      this.$dialog.alert({
        title: msisdn,
        message: message,
      }).then(() => {
        // on close
      });
    },
    getSearchParams(obj = this){
      const params = {};
      for (const [key, value] of Object.entries(obj.search)){
        if(value !== "") params[key] = value;
      }
      const {page: currentPage, pageSize} = obj.pageInfo;
      Object.assign(params, {currentPage, pageSize});
      return params;
    },
    onMsisdnChange(value, objName){
      debounce(() => {
        this[objName].pageInfo.page = 1;
        this[objName].pageInfo.pageSize = 10;
        this[objName].pageInfo.count = 1;
        const params = this.getSearchParams(this[objName]);
        this.fetchData(params, objName);
      });
    },
    onConfirm(value, objName){
      this[objName].statusText = value.label;
      this[objName].search.status = value.value;
      this[objName].pageInfo.page = 1;
      this[objName].pageInfo.pageSize = 10;
      this[objName].pageInfo.count = 1;
      const params = this.getSearchParams(this[objName]);
      this.fetchData(params, objName);
      this[objName].showPicker = false;
    }
  },
  created() {
    this.$store.commit('setPageTitle', "短信服务");
    this.fetchData({dir: 1, currentPage: 1, pageSize: 10}, "sentSms");
    this.fetchData({dir: 2, currentPage: 1, pageSize: 10}, "inBox");
  },
}
</script>
<style lang="scss" scoped>
.info {
  width: 750px;
  height: 255px;
  background-color: #209df2;

  &-img {
    float: left;
    width: 195px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 134px;
  }

  ul {
    float: left;
    margin: 38px 0 0 18px;
    width: 520px;

    li {
      color: #fff;
      font-size: 26px;
      text-align: left;
      height: 61.5px;
      line-height: 61.5px;
    }
  }
}

.orderForm {
  text-align: left;

  .van-submit-bar {
    border-top: 1px solid #ddd;
  }
}

.mySwipe {
  width: 90%;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 600;

  .swipeTitle {
    color: #EB5E00;
    margin: 20px 0px 0 24px;
    font-size: 22px;
  }

  .swipeDiv {
    text-align: center;
    padding: 20px 0;
    border: 1px solid #ddd;
    margin: 5%;

  }

  .active {
    color: #EB5E00;
    border: 1px solid sandybrown;
  }
}

.router {
  padding-top: 0;
}

</style>